.icon {
  display: inline-block;
  transition: transform 0.3s;
  background-repeat: no-repeat;
}
.arrow {
  background-image: url("./arrow.svg");
  width: 15px;
  height: 12px;
}
.download {
  background-image: url("./download.svg");
  width: 15px;
  height: 15px;
}
.close {
  cursor: pointer;
  background-image: url("./close.svg");
  width: 24px;
  height: 24px;
}
