.backdrop {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
}
.modal {
  margin: 16px;
  max-height: calc(100% - 32px);
  max-width: 600px;
  overflow-y: auto;
  transition: height 0.25s ease-in;
  width: calc(100% - 32px);
}
.modalContent {
  background-clip: padding-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
}
.modalHeader {
  align-items: center;
  border-bottom: 1px solid #ccc;
  display: flex;
  font-weight: 300;
  justify-content: space-between;
  padding: 16px;
}
.close,
.close:focus,
.close:hover {
  background-color: #eee;
  border: none;
  box-shadow: none;
  color: #333;
  cursor: pointer;
  display: block;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  padding: 8px;
  text-decoration: none;
}
.modalBody {
  flex: 1 1 auto;
  padding: 16px;
  position: relative;
}
.hidden {
  display: none;
}
