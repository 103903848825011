.button,
.button:hover,
.button[type="submit"],
.button[type="submit"]:hover {
  background-color: #333;
  border-radius: 1px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: 300;
  height: auto;
  letter-spacing: 0.53px;
  margin: 0;
  min-height: 40px;
  min-width: 120px;
  padding: 8px;
}
.spinnerWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}
.spinner {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  display: inline-block;
  height: 16px;
  width: 16px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
