.table {
  background-color: #fff;
  border-collapse: separate;
  border-radius: 0px;
  border-spacing: 0;
  color: #333;
  width: 100%;
}
.tr {
  background-color: #fff;
  color: #333;
}
.tr:hover {
  background-color: #eee;
}
.th {
  background-color: #fff;
  border-bottom: 1px solid #333;
  border-left: none;
  border-radius: 0px;
  border-right: 1px solid #333;
  border-top: 1px solid #333;
  color: #333;
  cursor: pointer;
  font-weight: 300;
  padding: 12px;
  position: sticky;
  text-align: left;
  top: 0;
}
.th:first-child {
  border-left: 1px solid #333;
}
.th.sort {
  background-color: #eee;
}
.thLabel {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.asc {
  transform: rotate(0deg);
}
.desc {
  transform: rotate(-180deg);
}
.hide {
  visibility: hidden;
}
.td {
  background-color: #fff;
  border-bottom: 1px solid #333;
  border-left: none;
  border-radius: 0px;
  border-right: 1px solid #333;
  border-top: none;
  color: #333;
  cursor: pointer;
  font-weight: 300;
  padding: 12px;
  text-align: left;
}
.td:first-child {
  border-left: 1px solid #333;
}
.a,
.a:hover {
  background-color: #fff;
  border: 0;
  color: #eee;
}
.hideSm {
  display: none;
}
@media (min-width: 600px) {
  .hideSm {
    display: table-cell;
  }
}
