.flex {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.details {
  column-gap: 16px;
  display: grid;
  font-weight: 300;
  grid-template-columns: repeat(2, 1fr);
  line-height: initial;
  row-gap: 8px;
  width: 100%;
}
@media screen and (min-width: 600px) {
  .details {
    width: 50%;
  }
}
.form {
  margin: 0;
  padding: 0;
}
.grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(6, 1fr);
}
.spacing {
  margin-top: 32px;
}
.organizationName {
  grid-column: 1 / span 3;
}
.organizationNumber {
  grid-column: 4 / span 3;
}
.name {
  grid-column: 1 / span 3;
}
.email {
  grid-column: 4 / span 3;
}
.phone {
  grid-column: 1 / span 2;
}
.zip {
  grid-column: 3 / span 2;
}
.birthYear {
  grid-column: 5 / span 2;
}
.buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 16px;
}
.cancel,
.cancel:hover {
  color: #333;
  cursor: pointer;
  display: block;
  font-weight: 300;
  margin-right: 16px;
  margin: 0;
  padding: 8px;
}
.a,
.a:hover {
  background-color: #fff;
  border: 0;
  color: #333;
  height: auto;
}
