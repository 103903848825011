.div {
  background-color: rgb(224, 224, 224);
  border-radius: 16px;
  display: inline-block;
  font-weight: 300;
  line-height: 100%;
  padding: 4px 8px;
}
.available {
  background-color: rgb(129, 199, 132);
}
.reserved {
  background-color: rgb(255 213 79);
}
.booked {
  background-color: rgb(255, 183, 77);
}
.sold {
  background-color: rgb(229, 115, 115);
}
.upcoming {
  background-color: rgb(224, 224, 224);
}
