.flex {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.body {
  font-weight: 300;
}
.button,
.button:hover {
  background-color: #333;
  border-radius: 1px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: 300;
  letter-spacing: 0.53px;
  margin: 0;
  min-width: 120px;
  padding: 8px;
}
