.label {
  background-color: #fff;
  border: none;
  color: #333;
  font-weight: 300;
  margin: 0;
  padding: 0;
}
.input,
.input[type="text"] {
  background-color: #fff;
  border-color: #333;
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: #333;
  font-weight: 300;
  height: auto;
  margin: auto;
  padding: 8px;
  width: 100%;
}
.inputError {
  border-color: red;
}
.labelError {
  color: #d32f2f;
  font-weight: 300;
  list-style: none;
}
